@import '../../assets/scss/variables';

.sidenav {
	border-right: 1px solid lighten($primaryLight, 30%);
	display: inline-block;
	margin-right: $spacing6;
	position: fixed;
	left: 0;
	height: calc(100vh - 76px);
	top: 76px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 250px;
	z-index: 2;
	background-color: $light;
	&__helper {
		width: 250px;
		@media screen and (max-width: 830px) {
			display: none;
		}
	}
	@media screen and (max-width: 830px) {
		display: none;
		width: 100%;
	}
}

#navMenu {
	@media screen and (max-width: 830px) {
		display: none !important;
	}
}

#navMenuMobile {
	display: none;
}

#navMenuHelper {
	@media screen and (max-width: 830px) {
		display: none !important;
	}
}

#navMenuHelperMobile {
	display: none;
}


.navMenuMobileBtn {
	@media screen and (min-width: 831px) {
		display: none !important;
	}
	width: 100%;
	border-top: 1px solid #e8e8e8;
	z-index: 10;
	color: #1f1a4f;
	display: none;
	position: fixed;
	background: white;
	padding: 12px;
	font-weight: 500;
	bottom: 0;
	@media screen and (max-width: 830px) {
		display: block;
	}
}

.mobileClose {
	display: none;
	color: white;
	background: #1f1a4f;
	opacity: 0.9;
}
