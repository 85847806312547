@import '../../../assets/scss/variables';

.multi-select {
	border: 1px solid $borderColor;
	border-radius: $borderRadiusInput;
	position: relative;
	min-width: 130px;
	&.active {
		input {
			background-color: $primaryLight !important;
			color: $white !important;
		}
	}
	input[type='text'] {
		background-color: $white;
		transition: background-color 0.2s ease;
		border: none;
		color: $primary;
		padding: 0 $spacing4 0 $spacingBase;
		height: $spacing5;
		white-space: pre;
		font-size: 1rem;
		width: 100%;
		cursor: pointer;
		position: relative;
		width: 100%;
		svg {
			position: absolute;
			right: $spacingSS;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&__values-container {
		background-color: $white;
		max-height: 200px;
		width: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		position: absolute;
		z-index: 99;
		border-right: 1px solid $inputFocus;
		border-bottom: 1px solid $inputFocus;
		border-left: 1px solid $inputFocus;
		border-color: $inputFocus;
		border-bottom-right-radius: $borderRadiusInput;
		border-bottom-left-radius: $borderRadiusInput;
		box-sizing: content-box;
		left: -1px;
		.input-checkbox {
			padding: $spacingBase;
			label {
				display: flex;
			}
		}
	}
	&.expanded {
		border-top-color: $inputFocus;
		border-left-color: $inputFocus;
		border-right-color: $inputFocus;
		input[type='text'] {
			border-bottom: 1px solid $borderColor;
		}
	}
}
