// Spacing
$spacingBase: 8px;
$spacingHalf: $spacingBase / 2;
$spacingSS: $spacingBase * 1.5;
$spacing2: $spacingBase * 2;
$spacing3: $spacingBase * 3;
$spacing4: $spacingBase * 4;
$spacing5: $spacingBase * 5;
$spacing6: $spacingBase * 6;
$spacing7: $spacingBase * 7;
$spacing8: $spacingBase * 8;
$spacing9: $spacingBase * 9;
$spacing10: $spacingBase * 10;
// Fonts
// prettier-ignore
$mainFont: 'Averta', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
  Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;
// prettier-ignore
$readFont: Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif', Lucida,
  'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', Georgia, serif;
$bold: 800;
$semibold: 600;
$normal: 400;
$thin: 300;

// Colors
$primary: #423d6c;
$primaryLight: #8f8ca7;
$primaryDark: #201a50;
$primaryMedium: #686486;
$facebook: #375c8e;
$google: #df8071;
$yellow: #f4e839;

$danger: #ee2319;
$warning: #ffc107;
$success: #00c853;

$black: #090909;
$dark: #3a3a3a;
$white: #ffffff;
$light: #f2f2f2;
$medium: #dfdfdf;
$link: #6d85f5;
$inputFocus: $primaryLight;

$textDark: #3a3a3a;
$textGrey: #656161;
$textLight: #a7a7a7;

$boxShadowColor: #686868;

$borderColor: #e8e8e8;

$borderRadiusBtn: 3px;
$borderRadiusInput: 3px;
$borderRadiusCard: 3px;
$borderRadiusLight: 2px;
$borderRadiusDialog: 3px;

$boxShadowInput: 0 20px 35px rgba(0, 0, 0, 0.1);
