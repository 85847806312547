@import '../../../assets/scss/variables';

label.label {
	display: block;
	font-weight: 400;
	margin-bottom: $spacingBase;
	color: $primary;
	// opacity: 0.8;
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 1;
	position: relative;
	min-height: 20px;
	&.private {
		.label__title {
			margin-left: $spacing3;
		}
	}
	svg {
		margin-left: $spacingHalf;
	}
	.hint--private {
		margin-right: $spacingBase;
		position: absolute;
		left: 0;
	}
}
