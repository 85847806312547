@import '../../assets/scss/variables';

.toggle {
	position: relative;
	display: inline-block;
	cursor: pointer;
	&__background {
		width: 40px;
		height: 20px;
		&__left,
		&__right {
			width: 18px;
			height: 20px;
			display: inline-block;
			background-color: lightgrey;

			transition: background-color 0.3s ease;
		}
		&__left {
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
		}
		&__right {
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;
		}
	}
	&__circle {
		width: 16px;
		height: 16px;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		left: 2px;
		top: 2px;
		transition: left 0.3s ease;
	}
	&.active {
		.toggle__background__right,
		.toggle__background__left {
			background-color: $success;
		}
		.toggle__circle {
			left: 18px;
		}
	}
	&.disabled {
		.toggle__background__right,
		.toggle__background__left {
			opacity: 0.2;
			cursor: default !important;
		}
		.toggle, .toggle__circle {
			cursor: default !important;
		}
	}
}
