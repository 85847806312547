@import '../../../assets/scss/variables';

.page--custom-email {
	flex: 1;
	.table-header,.table-content,.table-footer{
		max-width: calc(100vw - 85px) !important;
		@media screen and (max-width: 936px) and (min-width: 831px) {
			width: 500px !important;
		}
	}
	.footer {
		max-width: calc(100vw - 100px) !important;
	}
	.wrapper {
		max-width: calc(100vw - 85px) !important;
		@media screen and (max-width: 936px) and (min-width: 831px){
			width: 500px !important;
		}
	}
	.email-preview {
		margin-top: 18px;
		margin-bottom: 40px;
		border: 18px solid $white;
		border-radius: 18px;
		position: sticky;
		word-break: break-word;
		max-width: 1000px;
	}
	.cm {
		&__back {
			display: inline-block;
			margin-bottom: $spacing3;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
