@import '../../../assets/scss/variables';
.formik-submit {
	.form-input__validation-error {
		margin-bottom: $spacingBase;
	}
	&__btn--submit {
		.btn__content {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		svg {
			margin-left: $spacingBase;
		}
	}
}
