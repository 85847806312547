@import '../../../assets/scss/variables';

.page--add-contact {
	// .dialog {
	// 	width: 540px;
	// }
	// .double-text-select {
	// 	box-shadow: none;
	// 	border: 1px solid $borderColor;
	// }
	// .input-row {
	// 	display: flex;
	// 	align-items: flex-start;
	// 	margin-bottom: $spacingBase;
	// 	&:last-child {
	// 		margin-bottom: 0;
	// 	}
	// }
	.form-success {
		width: 540px;
		max-width: 100%;
		height: 100%;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		> svg {
			margin-bottom: $spacing3;
		}
		> div {
			margin-bottom: $spacing3;
		}
	}
}
