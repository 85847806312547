@import '../../../assets/scss/variables';

.page--contact-faq {
	flex: 1;
	overflow-x: auto;
	.dialog {
		width: 100%;
		margin-bottom: 0;
		&__content {
			padding: 0;
			overflow-x: auto;
		}
	}
	.row {
		&__id {
			color: $textGrey;
			opacity: 0.6;
			font-size: 0.8rem;
		}
	}
}
