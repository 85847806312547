@import '../../../assets/scss/variables';

.input-checkbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	label {
		display: inline-flex;
		align-items: center;
		position: relative;
		&:hover {
			cursor: pointer;
		}
	}
	&__fake {
		width: $spacing3;
		height: $spacing3;
		border: 1px solid darken($borderColor, 20%);
		transition: border-color 0.2s ease, background-color 0.2s ease;
		border-radius: $borderRadiusInput;
		background-color: $light;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			opacity: 0;
			transition: opacity 0.1s ease;
		}
	}
	&.white {
		.input-checkbox__fake {
			background-color: $white;
		}
	}
	&.required {
		.input-checkbox__fake {
			border-color: $danger;
			background-color: lighten($danger, 47%);
		}
	}
	&__text {
		margin-left: $spacingBase;
	}
	input {
		display: none;
		position: relative;
		&:checked {
			+ span {
				svg {
					opacity: 1;
				}
			}
		}
	}
	&.circle {
		.input-checkbox__fake {
			width: $spacing4;
			height: $spacing4;
			border-radius: 50%;
		}
		input:checked {
			+ span {
				background-color: $primary;
			}
		}
	}
	.tippy__icon {
		margin-left: $spacingBase;
	}
	&.reverse {
		label {
			flex-direction: row-reverse;
			.input-checkbox__text {
				margin-left: 0;
				margin-right: $spacingBase;
			}
		}
	}
	&.small {
		.input-checkbox__fake {
			width: $spacing3;
			height: $spacing3;
			border: 1px solid darken($borderColor, 20%);
			transition: border-color 0.2s ease, background-color 0.2s ease;
			border-radius: $borderRadiusInput;
			background-color: $light;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
