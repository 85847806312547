@import '../../../assets/scss/variables';

.double-select {
	display: inline-flex;
	border-radius: $borderRadiusInput;
	background-color: $white;
	align-items: center;
	transition: border-color 0.2s ease, background-color 0.2s ease;
	.input-select {
		border: none;
		position: relative;
		min-width: 135px;
		select {
			box-shadow: none !important;
		}
	}
	&__divider {
		background-color: #fff;
		height: 100%;
		width: 2px;
		display: flex;
		align-items: center;
		&__inner {
			background-color: darken($light, 5);
			height: $spacing3;
			width: 100%;
		}
	}
}
