@import '../../assets/scss/variables';

.tippy__icon {
	line-height: 0;
	display: inline-block;
	&:focus {
		outline: none;
	}
}

.hint-text {
	font-size: 1rem;
	color: $primary;
}

.tippy-tooltip {
	text-align: left;
	border: 1px solid darken($light, 10%);
	padding: $spacingSS;
	border-radius: $borderRadiusDialog !important;
}
.tippy-popper {
	z-index: 11001 !important;
}
