@import '../../../../assets/scss/variables';

input.flatpickr-input {
  border: 1px solid $borderColor;
  box-shadow: none;
  width: 100%;
  padding: 11px 11px 9px;
  font-size: 19px;
  line-height: 24px;
  min-width: 130px;
}

.to-be-checked-label {
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: #fafafa;
  display: inline-block;
  padding: 10px 15px;
  cursor: default;
}

.assignments-list {
  margin-right: 90px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: -15px;
  margin-left: 10px;

  .assignment-single {
    margin-right: 7px;
    padding-left: 7px;
    margin-top: 15px;
    border-left: 1px solid $boxShadowColor;
    &.clickable {
      cursor: pointer;
      &:hover {
        opacity: 0.5;
        text-decoration: line-through;
      }
    }
  }

  .assignment-single:first-child {
    padding-left: 0;
    border-left: none;
  }
}
