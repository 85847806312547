@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  line-height: 1; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  border: none;
  padding: 0;
  font-size: inherit;
  background-color: transparent; }
  button:focus {
    outline: none; }

select {
  -webkit-appearance: none; }
  select::-ms-value {
    background: none; }
  select:focus {
    outline: none; }

input {
  font-family: inherit; }
  input:focus {
    outline: none; }

* {
  box-sizing: border-box; }

/* ----------------------------------- //
//      CSS Hacks Mixins for Sass
// ----------------------------------- //

	How to use:

	@include [Mixin_Name]( 'selector', (property: value) )

	Example:

	@include only_ie9( '.my_element', (color: red) )
	@include only_ff28_above( '.my_element', (
		background-color: green,
		display: flex,
		margin: 2em,
	))

	Tips:
	  - Use firefox mixins before IE mixins. Sometimes Firefox wants to precess the IE css but it can't and skips it's own CSS!

// ----------------------------------- //

	List of Mixins:

	only_ff
	only_ff2
	only_ff2_above
	only_ff3_above
	only_ff6_above
	only_ff16_above
	only_ff21_above
	only_ff24_above
	only_ff25_above
	only_ff26_above
	only_ff27_above
	only_ff28_above
	only_ff30_above
	only_webkit
	only_chrome
	only_safari
	only_safari9
	only_ios
	only_safari_no_ios
	only_opera9_safari2
	only_opera
	only_opera11
	only_edge
	only_ie6
	only_ie7
	only_ie7_below
	only_ie8
	only_ie8_below
	only_ie9
	only_ie9_below
	only_ie10_above
	only_ie11
	only_ie11_above
	no_ie6
	only_ie9_saf4_above
	no_ie_safari6

// ----------------------------------- */
/* ----------------------------------- //
//          Firefox CSS Hacks
// ----------------------------------- */
/*--- Only Firefox ---*/
/*--- Only Firefox 1.5 and Firefox 2 ---*/
/*--- Only Firefox ≥ 2 ---*/
/*--- Only Firefox ≥ 3 ---*/
/*--- Only Firefox ≥ 6 ---*/
/*--- Only Firefox ≥ 16 ---*/
/*--- Only Firefox ≥ 21 ---*/
/*--- Only Firefox ≥ 24 ---*/
/*--- Only Firefox ≥ 25 ---*/
/*--- Only Firefox ≥ 26 ---*/
/*--- Only Firefox ≥ 27 ---*/
/*--- Only Firefox ≥ 28 ---*/
/*--- Only Firefox ≥ 30 ---*/
/* ----------------------------------- //
//          Webkit CSS Hacks
// ----------------------------------- */
/*--- Only Webkit (Chrome, Safari, Opera ≥ 14) ---*/
/*--- Only Chrome 28+, Opera ≥ 14 ---*/
/*--- Only Safari ≥ 6.1 ---*/
/*--- Only Safari ≥ 9 ---*/
/*--- Only iOS Safari ≥ 9 ---*/
/*--- Only Safari ≥ 9 (Non iOS) ---*/
/*--- Only Opera ≤ 9.27, Safari ≤ 2 ---*/
/* ----------------------------------- //
//           Opera CSS Hacks
// ----------------------------------- */
/*--- Only Opera ≥ 9.5 ---*/
/*--- Only Opera ≤ 11 ---*/
/* ----------------------------------- //
//     Edge CSS Hacks
// ----------------------------------- */
/* ----------------------------------- //
//     Internet Explorer CSS Hacks
// ----------------------------------- */
/*--- Only IE ≤ 6 ---*/
/*--- Only IE7 ---*/
/*--- Only IE ≤ 7 ---*/
/*--- Only IE8 ---*/
/*--- Only IE ≤ 8 ---*/
/*--- Only IE9 ---*/
/*--- Only IE ≤ 9 ---*/
/*--- Only IE10 ---*/
/*--- Only IE ≤ 11 ---*/
/*--- Only IE ≥ 11 ---*/
/*--- Anything but IE6 ---*/
/*--- Only IE ≥ 9, safari4, android ≥ 2.3 ---*/
/* ----------------------------------- //
//           Other CSS Hacks
// ----------------------------------- */
/*--- Everything but IE and Safari ≤ 6 ---*/
@font-face {
  font-family: 'Averta';
  src: url("../fonts/340430_2_0.ttf");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Averta';
  src: url("../fonts/340430_2_0.ttf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Averta';
  src: url("../fonts/340430_3_0.ttf");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Averta';
  src: url("../fonts/340430_0_0.ttf");
  font-weight: 800;
  font-style: normal; }

html {
  font-size: 16px;
  font-family: "Averta", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif; }

body {
  font-size: 1rem;
  font-family: "Averta", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400; }

p {
  margin: 0; }

blockquote {
  color: #423d6c;
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.6;
  margin: 24px; }

small {
  font-size: 0.8rem; }

h3 {
  margin: 0; }

h2 {
  margin: 0; }

h1 {
  margin: 0; }

.super {
  font-size: 4rem;
  line-height: 1; }
  @media screen and (max-width: 768px) {
    .super {
      font-size: 3rem; } }
  @media screen and (max-width: 425px) {
    .super {
      font-size: 2.5rem; } }

@keyframes zoomIn {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0); }
  70% {
    transform: translateX(-50%) translateY(-50%) scale(1.1); }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(1); } }

.fade-enter {
  opacity: 0; }

.fade-enter.fade-enter-active {
  transition: opacity 0.4s ease;
  opacity: 1; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 0.4s ease; }

.fade-enter-done {
  opacity: 1 !important; }

.fade-up-enter {
  opacity: 0.01;
  transform: translateY(50px); }

.fade-up-enter.fade-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.2s ease, transform 0.2s ease; }

.fade-up-exit {
  transform: translateY(0);
  opacity: 1; }

.fade-up-exit.fade-up-exit-active {
  transform: translateY(50px);
  opacity: 0.01;
  transition: opacity 0.2s ease, transform 0.2s ease; }

.form-validation-enter {
  opacity: 0.01;
  max-height: 0; }

.form-validation-enter.form-validation-enter-active {
  opacity: 1;
  max-height: 80px;
  transition: opacity 0.2s ease, max-height 0.2s ease; }

.form-validation-exit {
  max-height: 80px;
  opacity: 1; }

.form-validation-exit.form-validation-exit-active {
  max-height: 0;
  opacity: 0.01;
  transition: opacity 0.2s ease, max-height 0.2s ease; }

.form-validation-enter-done {
  max-height: 80px; }

.height-opacity-enter {
  opacity: 0.01;
  max-height: 0; }

.height-opacity-enter.height-opacity-enter-active {
  opacity: 1;
  max-height: 80px;
  transition: opacity 0.2s ease, max-height 0.2s ease, padding 0.2s ease; }

.height-opacity-enter-done {
  max-height: 80px; }

.height-opacity-exit {
  max-height: 80px;
  opacity: 1; }

.height-opacity-exit.height-opacity-exit-active {
  max-height: 0;
  opacity: 0.01;
  padding: 0;
  transition: opacity 0.2s ease, max-height 0.2s ease, padding 0.2s ease; }

html,
body {
  color: #423d6c;
  background-color: #f2f2f2;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  line-height: 1.15;
  width: 100%;
  height: 100%; }

#root {
  flex: 1; }

main {
  flex: 1;
  background-color: #f2f2f2;
  position: relative;
  height: calc(100% - 80px); }
  main .page {
    padding: 24px;
    overflow-x: auto; }

.router-wrapper {
  display: flex;
  align-items: flex-start; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600; }

a {
  color: inherit;
  text-decoration: none;
  outline: none; }

.link {
  color: #6d85f5 !important; }
  .link:hover {
    cursor: pointer;
    text-decoration: underline; }

.text-center {
  text-align: center; }

.noselect,
svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

svg .fill {
  fill: #e9e8ee; }

.noscroll {
  overflow: hidden !important;
  -webkit-overflow-scrolling: auto; }

.visibility-hidden {
  visibility: hidden; }

button {
  border-radius: 3px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.box-shadow {
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1); }
  .box-shadow--dialog {
    box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.1); }
  .box-shadow--navbar {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1); }
  .box-shadow--input {
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1); }
  .box-shadow--light {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }

input,
textarea {
  font-size: 1rem;
  font-family: "Averta", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input::placeholder,
  textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #201b50;
    opacity: 0.5;
    /* Firefox */
    font-family: "Averta", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bababa; }
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bababa; }

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none; }

hr {
  border-top: 1px solid #dbdbdb; }

.text-danger {
  color: #ee2319; }

.text-warning {
  color: #ffc107; }

.text-success {
  color: #00c853; }

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline; }

.form-field {
  margin-bottom: 16px; }
  .form-field .form-field__validation-error {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #ee2319; }

small {
  font-size: 13px; }

svg {
  line-height: 0;
  pointer-events: none; }
  svg:focus {
    outline: none; }

::-moz-selection {
  color: #ffffff;
  background: #423d6c; }

::selection {
  color: #ffffff;
  background: #423d6c; }

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center; }

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #423d6c;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #d9d9d9;
  cursor: pointer; }

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  border: 1px solid #423d6c;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #d9d9d9;
  cursor: pointer; }

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  border: 1px solid #423d6c;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #d9d9d9;
  cursor: pointer; }

input[type='range']::-webkit-slider-runnable-track {
  cursor: pointer;
  background: #423d6c;
  border-radius: 3px;
  border: 0.2px solid #e8e8e8; }

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #423d6c; }

input[type='range']::-moz-range-track {
  cursor: pointer;
  background: #423d6c;
  border-radius: 3px;
  border: 0.2px solid #e8e8e8; }

input[type='range']::-ms-track {
  cursor: pointer;
  background: #423d6c;
  border-radius: 3px;
  border: 0.2px solid #e8e8e8; }

input[type='range']::-ms-fill-lower {
  cursor: pointer;
  background: #423d6c;
  border-radius: 3px;
  border: 0.2px solid #e8e8e8; }

input[type='range']:focus::-ms-fill-lower {
  background: #423d6c; }

input[type='range']::-ms-fill-upper {
  cursor: pointer;
  background: #423d6c;
  border-radius: 3px;
  border: 0.2px solid #e8e8e8; }

input[type='range']:focus::-ms-fill-upper {
  background: #423d6c; }

p {
  line-height: 1.15; }

.skeleton {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden; }
  .skeleton:before {
    content: '';
    width: 30%;
    height: 100%;
    background: linear-gradient(to right, rgba(239, 239, 239, 0) 0%, rgba(239, 239, 239, 0.8) 15%, #efefef 19%, #efefef 20%, white 50%, #efefef 80%, #efefef 81%, rgba(239, 239, 239, 0.8) 85%, rgba(239, 239, 239, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#fff',GradientType=1 );
    /* IE6-9 */
    position: absolute;
    opacity: 0.6;
    z-index: 1;
    animation: mymove 1s infinite; }

@keyframes mymove {
  from {
    left: -30%; }
  to {
    left: 100%; } }

/* Safari 4.0 - 8.0 */
@-webkit-keyframes mymove {
  from {
    left: -30%; }
  to {
    left: 100%; } }

.flex {
  display: flex; }
  .flex--end--between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
  .flex--start--between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .flex--center {
    display: flex;
    align-items: center;
    justify-content: center; }
  .flex--center--start {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .flex--center--between {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .flex--column {
    flex-direction: column; }
  .flex--row {
    flex-direction: row; }

.color--danger {
  color: #ee2319; }

.color--warning {
  color: #ffc107; }

.color--success {
  color: #00c853; }

.rt-resizable-header {
  outline: none; }

.rt-td {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.rt-tr-group:nth-child(even) {
  background-color: #f9f9f9; }
