@import '../../../assets/scss/variables';

.page--edit-contact {
	display: flex;
	flex: 1;
	> div:first-child {
		margin-right: 4%;
	}
	@media screen and (max-width: 1300px) {
		flex-direction: column;
	}
	.dialog {
		max-width: 540px;
		&--emails-sent,
		&--messages,
		&--calls {
			.dialog__content {
				padding: 0;
			}
			.inner-content {
				max-height: 434px;
				overflow-y: auto;
			}
			.dialog__footer {
				padding: $spacing2;
				display: flex;
				justify-content: space-between;
			}
		}
	}
	.call {
		border-bottom: 1px solid $borderColor;
		margin-bottom: $spacing2;
		padding-bottom: $spacing2;
		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
		.date {
			font-weight: 600;
		}
		.caller {
			margin: $spacing2 0;
			.name {
				font-weight: 400;
				color: $google;
			}
			.phone {
				font-weight: 400;
				color: $google;
			}
		}
		.comments {
			border: 1px solid $borderColor;
			padding: $spacingBase;
			border-radius: 5px;
			background-color: lighten($light, 3%);
			color: $primaryLight;
			word-break: break-word;
		}
	}
	.dialog--statuses {
		.toggle-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: $spacing2;
			border: 1px solid $borderColor;
			padding: $spacingBase;
			border-radius: 3px;
			background-color: lighten($light, 3%);
			&:last-child {
				margin-bottom: 0;
			}
			span {
				margin-left: $spacingBase;
			}
		}
	}

	.SingleDatePicker,
	.SingleDatePickerInput,
	.DateInput,
	.DateInput_input {
		width: 100%;
	}
	.toggle--to-be-checked {
		flex-direction: column;
		align-items: flex-start !important;
		.the-toggle {
		}
		.label {
			margin-top: $spacingBase;
		}
		.reason {
			color: $google;
		}
	}
	.checked-by {
		color: $google;
		margin-bottom: $spacing2 !important;
	}
	.edit--contact-buttons-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
