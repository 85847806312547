@import '../../../../assets/scss/variables';

.phone-input {
	display: flex;
	align-items: flex-start;
	width: 100%;
	.form-input:last-child {
		flex: 1;
	}
	//&.default {
	//	.input-select.flat select, .input-text {
	//		border: 1px solid $primary;
	//	}
	//}
	.make-default-icon {
		opacity: 0.1;
	}
	.favorite-static {
		svg {
			margin: 13px 15px 0px 3px;
			width: 16px;
		}
	}
}
