@import '../../assets/scss/variables';

.dialstringContainer svg {
  position: relative !important;
  right: 25px !important;
}

.phone-interface {
  .container {
    width: 100% !important;
    @media(max-width: 500px){
      width: auto !important;
      .dialstringContainer {
        flex-direction: column !important;
        .dialInput {
          //margin-bottom: 20px;
        }
        .dialButton {
          width: 40px;
        }
        svg {
          margin-right: 10px !important;
          top: -23px !important;
          right: auto !important;
        }
      }
    }
  }
}
