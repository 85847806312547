@import '../../../assets/scss/variables';

.double-text-select {
	display: inline-flex;
	border-radius: $borderRadiusInput;
	background-color: $white;
	align-items: center;
	transition: border-color 0.2s ease, background-color 0.2s ease;
	&.required {
		border-color: $danger;
		background-color: lighten($danger, 47%);
	}
	.input-text {
		width: 120px;
		max-width: 100%;
		box-shadow: none;
		input {
			border-color: transparent;
			&:focus {
				border-color: $inputFocus;
			}
		}
	}
	.input-select {
		border: none;
		position: relative;
		min-width: 135px;
		select {
			box-shadow: none !important;
		}
	}
	&__divider {
		background-color: $white;
		height: 100%;
		width: 2px;
		display: flex;
		align-items: center;
		&__inner {
			background-color: darken($light, 5);
			height: $spacing3;
			width: 100%;
		}
	}
}
