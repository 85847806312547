@import '../../../assets/scss/variables';

.popup {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 10001;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	&__flex-helper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: 100vh;
		padding: $spacing4 $spacing3;
		flex-direction: column;
	}
	&__dialog {
		background-color: $white;
		border-radius: $borderRadiusBtn;
		position: relative;
		max-width: 100%;
		box-shadow: 0 10px 40px 0 lightgrey;
	}
	&__close {
		background-color: $primaryLight;
		width: $spacing4;
		height: $spacing4;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: -16px;
		top: -16px;
		&:hover {
			cursor: pointer;
		}
	}
	&__header {
		padding: $spacing3;
		border-bottom: 1px solid $borderColor;
		&__title {
			color: $primaryDark;
			font-size: 1.3rem;
		}
	}
	&__content {
		padding: $spacing3;
	}
	&__footer {
		margin-top: $spacing3;
		max-width: 100%;
		padding: $spacing2 $spacing3;
		background-color: $white;
		border-radius: $borderRadiusBtn;
	}
}
