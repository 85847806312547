@import '../../assets/scss/variables';

.popup__background {
	background-color: rgba(255, 255, 255, 0.7);
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}
