@import '../../../../assets/scss/variables';

.page--custom-email__editor {
	.rich-editor__wrapper {
		max-width: 100%;
		border: 1px solid $borderColor;
		max-width: 1000px;
	}
	.rich-editor__toolbar {
		margin-bottom: 0;
	}
	.rich-editor__editor {
		flex: 1;
		background-color: $white;
		padding: 0 $spacing3;
		min-height: 180px;
	}
	/*
  * EMAIL TEMPLATE STYLING
  */
	.ExternalClass {
		width: 100%;
	}
	.ExternalClass,
	.ExternalClass p,
	.ExternalClass span,
	.ExternalClass font,
	.ExternalClass td,
	.ExternalClass div {
		line-height: 100%;
	}

	body,
	.mail {
		width: 100% !important;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		background-color: #f2f2f2;
		margin: 0;
		padding: 0;
		font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
		-webkit-font-smoothing: antialiased;
		mso-margin-top-alt: 0px;
		mso-margin-bottom-alt: 0px;
		mso-padding-alt: 0px 0px 0px 0px;
	}

	.mail * {
		box-sizing: border-box;
	}

	.templateContainer {
		margin: 0 auto;
		width: 600px;
	}

	.signature p {
		color: #464469;
		line-height: 20px;
		margin: 0 0 10px 0;
	}

	.signature a {
		color: #464469;
		cursor: pointer;
	}

	/*        .img {
        background-image: url(https://d530gosedmutm.cloudfront.net/media/5b03f63780652.jpg);
        height: 300px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        box-sizing: border-box;
        margin-bottom: 25px;
        display: block;
        }*/
	.button-yellow {
		display: inline-block;
		min-width: 150px;
		margin-top: 8px !important;
		background-color: #f4e839;
		color: black !important;
		padding: 15px 20px;
		border: solid 2px #f4e839;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		margin: 20px 0;
		text-align: center;
	}

	.similarlistings-title {
		font-size: 17px !important;
		margin-top: 30px;
		font-weight: 600;
		color: #211a50 !important;
		margin-bottom: 0px;
	}

	.innerContainer {
		position: relative;
		padding: 0 10px 10px 10px;
		padding-top: 11px !important;
		padding-bottom: 15px !important;
		padding-left: 24px !important;
		padding-right: 24px !important;
		z-index: 2;
		box-sizing: border-box;
	}

	a {
		text-decoration: none;
	}
	p,
	a,
	li,
	td,
	blockquote {
		mso-line-height-rule: exactly;
	}
	a[href^='tel'],
	a[href^='sms'] {
		color: inherit;
		cursor: default;
		text-decoration: none;
	}
	p,
	a,
	li,
	td,
	body,
	table,
	blockquote {
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
	}

	b,
	strong {
		font-size: 16px;
	}

	strong {
		font-size: 17px !important;
	}

	h1 {
		color: #201b50;
		font-family: Helvetica;
		font-size: 26px;
		font-style: normal;
		font-weight: bold;
		line-height: 125%;
		letter-spacing: normal;
		text-align: left;
		margin-bottom: 25px;
	}
	h2 {
		color: #202020;
		font-family: Helvetica;
		font-size: 22px;
		font-style: normal;
		font-weight: bold;
		line-height: 125%;
		letter-spacing: normal;
		text-align: left;
	}
	h3 {
		color: #202020;
		font-family: Helvetica;
		font-size: 20px;
		font-style: normal;
		font-weight: bold;
		line-height: 125%;
		letter-spacing: normal;
		text-align: left;
	}
	h4 {
		color: #202020;
		font-family: Helvetica;
		font-size: 18px;
		font-style: normal;
		font-weight: bold;
		line-height: 125%;
		letter-spacing: normal;
		text-align: left;
	}

	.mail p {
		line-height: 1.4em;
		font-size: 16px;
		color: black;
	}

	.centered-link {
		display: block;
		margin: 0 auto;
		text-align: center;
	}

	// .btn {
	// 	display: inline-block;
	// 	min-width: 150px;
	// 	background-color: #f4e839;
	// 	color: #000;
	// 	padding: 15px 20px;
	// 	border: solid 2px #f4e839;
	// 	border-radius: 4px;
	// 	outline: none;
	// 	font-size: 16px;
	// 	margin: 20px 0;
	// 	text-align: center;
	// }

	.btn-hollow {
		background-color: rgba(244, 232, 57, 0.14);
	}

	.brand {
		color: #464469;
		font-weight: 200;
		letter-spacing: 2px;
		font-size: 20px;
		margin: 15px 15px 0 15px;
	}

	.brand h1 {
		padding-top: 2px;
	}
	.brand a {
		text-decoration: none;
		-webkit-display: flex;
		-moz-display: flex;
		display: flex;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center;
	}
	.brand .logo {
		margin-right: 10px;
	}

	.shares a {
		position: relative;
		margin-right: 10px;
		transition: opacity 0.5s ease;
		opacity: 0.5;
	}

	.shares a:hover {
		opacity: 1;
	}

	.card {
		width: 100%;
		height: 300px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		box-sizing: border-box;
		z-index: 2;
		margin-bottom: 25px;
		display: block;
	}

	.image-spacer {
		height: 300px;
	}

	dl {
		margin-bottom: 0;
	}

	dt {
		font-weight: bold;
		margin-bottom: 5px;
		font-size: 15px;
	}

	dd {
		margin-left: 0;
		margin-bottom: 20px;
		font-size: 15px;
	}

	dd:last-child {
		margin-bottom: 0px;
	}

	.title {
		display: inline-block;
		margin-top: 0px;
		margin-bottom: 20px;
		font-weight: 400;
	}

	.footer {
		margin: 20px 15px;
		padding-bottom: 20px;
		color: #656565;
		font-size: 15px;
	}

	.footer a {
		color: #656565;
		text-decoration: underline;
	}

	.footer h2 {
		padding: 0;
		font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: bold;
		text-align: left;
		mso-line-height-rule: exactly;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		word-break: break-word;
		color: #656565;
		line-height: 150%;
	}

	.quotation {
		font-size: 1.1em;
		line-height: 1.4;
		color: #7175b5;
		margin-bottom: 50px;
	}

	.quotation:before {
		content: open-quote;
		display: inline;
		height: 0;
		line-height: 0;
		left: -10px;
		position: relative;
		top: 20px;
		color: #ccc;
		font-size: 3em;
	}
	.quotation::after {
		content: close-quote;
		display: inline;
		height: 0;
		line-height: 0;
		left: 10px;
		position: relative;
		top: 35px;
		color: #ccc;
		font-size: 3em;
	}

	.wrapper {
		position: relative;
		width: 600px;
		margin: 10px auto 20px auto;
	}

	table {
		border-spacing: 0;
	}
	.table-header,
	.table-content,
	.table-footer {
		border-spacing: 0;
		width: 600px;
	}

	.shadows-top-left {
		width: 35px;
		height: 28px;
		background-image: url('https://www.bednblue.com/img/png/shadow-top-left.png');
	}

	.shadows-top-center {
		background-color: white;
	}

	.shadows-top-right {
		width: 35px;
		height: 28px;
		background-image: url('https://www.bednblue.com/img/png/shadow-top-right.png');
	}

	.header-image {
		background-position: -20px;
	}

	.shadows-bottom-left {
		width: 35px;
		height: 50px;
		background-image: url('https://www.bednblue.com/img/png/shadow-bottom-left.png');
	}

	.shadows-middle-center {
		background-color: white;
	}

	.shadows-middle-left {
		width: 12px;
		/* background-image: url('https://www.bednblue.com/img/png/shadow-vertical.png'); */
	}

	.shadows-middle-right {
		width: 12px;
		/* background-image: url('https://www.bednblue.com/img/png/shadow-vertical.png'); */
		background-position: right;
	}

	.shadows-bottom-center {
		height: 50px;
		background-image: url('https://www.bednblue.com/img/png/shadow-bottom-horizontal.png');
	}

	.shadows-bottom-right {
		width: 35px;
		height: 50px;
		background-image: url('https://www.bednblue.com/img/png/shadow-bottom-right.png');
	}

	.table-thin {
		width: 100%;
		height: 100%;
	}

	.td-thin {
		width: 15px;
		height: 300px;
	}

	td {
		padding: 0;
	}

	.td-image {
		background-color: white;
	}

	.x_btn {
		display: inline-block;
		min-width: 150px;
		background-color: #201b50;
		color: #fff;
		padding: 12px 20px;
		border-radius: 4px;
		outline: none;
		font-size: 17px;
		margin: 10px 0;
		text-align: center;
		box-shadow: 0 3px 6px 1px #201b50;
		box-shadow: 1px 2px black;
	}
}
