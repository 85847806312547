@import './../../assets/scss/variables';

.btn {
	border-radius: $borderRadiusBtn;
	background-color: $primary;
	color: $white;
	padding: 0 !important;
	border: 1px solid transparent;
	width: 220px;
	max-width: 100%;
	&__inner {
		padding: $spacing2;
		position: relative;
		&__box-shadow {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			box-shadow: 0 5px 15px 0 $boxShadowColor;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
		}
		.loader-wrapper {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&.danger {
		background-color: $danger;
		.btn__inner__box-shadow {
			box-shadow: 0 5px 15px 0 lighten($boxShadowColor, 30%);
		}
	}
	&.warning {
		background-color: $warning;
		.btn__inner__box-shadow {
			box-shadow: 0 5px 15px 0 lighten($boxShadowColor, 20%);
		}
	}
	&.success {
		background-color: $success;
		.btn__inner__box-shadow {
			box-shadow: 0 5px 15px 0 lighten($boxShadowColor, 20%);
		}
	}
	&.transparent {
		background-color: transparent;
		color: $textGrey;
		padding: 0;
		border: 1px solid lighten($primaryLight, 25%);
		transition: border-color 0.2s ease;
		&:hover {
			border-color: $primary;
		}

		.btn__inner__box-shadow {
			display: none !important;
		}
		.btn__content {
			font-weight: 400;
			color: $primary;
		}
	}
	&.dashed {
		border-style: dashed;
		color: $primaryLight;
		transition: opacity 0.2s ease, border-color 0.2s ease;
		background-color: transparent;
		border-color: $primaryLight;
		opacity: 0.7;
		&:hover {
			color: $primary;
			opacity: 1;
		}
		.btn__inner__box-shadow {
			display: none;
		}
		.btn__content {
			color: $primary;
			font-weight: 400 !important;
		}
	}
	&__content {
		font-weight: 400;
	}
	&:hover {
		cursor: pointer;
		.btn__inner__box-shadow {
			opacity: 0.8;
			border-radius: $borderRadiusBtn;
		}
	}
	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
		.btn__inner__box-shadow {
			opacity: 0 !important;
		}
	}
	&.opacity {
		opacity: 0.7;
		transition: opacity 0.2s ease;
		&:hover {
			opacity: 1;
		}
	}
	&.grey {
		background-color: $light;
		color: $primary;
		border: 1px solid $medium;
		.btn__content {
			font-weight: 600;
		}
		.btn__inner__box-shadow {
			box-shadow: 0 5px 15px 0 lighten($boxShadowColor, 40%);
		}
	}
	&.flat {
		width: auto;
		background-color: transparent !important;
		// opacity: 0.7;
		.btn__content {
			text-decoration: underline;
		}
		transition: opacity 0.2s ease;
		font-size: 0.9rem;
		&:hover {
			opacity: 1;
		}
		.btn__inner {
			padding: 0 !important;
		}
		.btn__inner__box-shadow {
			display: none;
		}
		.btn__content {
			color: $primary;
			font-weight: 400 !important;
		}
	}
	&.small {
		width: auto;
		.btn__inner {
			padding: $spacingBase $spacingSS;
		}
		.btn__content {
			font-size: 14px;
			font-weight: 400;
		}
	}
}
