@import '../../../../assets/scss/variables';

.dialog--emails-sent {
	.email-row {
		border: 1px solid #e8e8e8;
		border-radius: 5px;
		padding: $spacingBase;
		background-color: #fafafa;
		margin: $spacing2;
		word-break: break-word;
		&:first-child {
			margin-top: $spacing2;
		}
		&:last-child {
			margin-bottom: 0;
		}
		.row {
			margin-bottom: $spacingBase;
			&:last-child {
				margin-bottom: 0;
			}
			&__label {
				font-weight: 600;
			}
			&__value {
				font-weight: 600;
				color: $google;
			}
		}
	}
}
