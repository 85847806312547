@import '../../../../assets/scss/variables';

.check-duplicate {
	margin-bottom: $spacing6;
	.error,
	.success {
		position: absolute;

		display: flex;
		align-items: center;
		margin-top: $spacingBase;
		p {
			margin-left: $spacingBase;
		}
	}
	.error {
		p {
			color: $danger;
		}
		a {
			margin-left: 10px;
			color: $link;
			cursor: pointer;
		}
	}
	.success {
		color: $success;
	}
}
