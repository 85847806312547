@import '../../../assets/scss/variables';
.input-textarea {
	position: relative;
	max-width: 100%;
	width: 100%;
	line-height: 0;
	textarea {
		width: 100%;
		background-color: $white;
		color: $primary;
		border: 1px solid transparent;
		padding: $spacingBase;
		font-size: inherit;
		border-radius: $borderRadiusInput;
		transition: border-color 0.2s ease, background-color 0.2s ease;
		resize: none;
		&:focus {
			outline: none;
			border-color: $inputFocus !important;
		}
	}
	&.resize {
		textarea {
			resize: vertical;
			min-height: $spacing6;
		}
	}
	&.has-icon {
		textarea {
			padding-right: $spacing5;
		}
	}
	&.flat {
		box-shadow: none;
		textarea {
			border-color: $borderColor;
		}
	}
	&.required {
		textarea {
			border-color: $danger !important;
			background-color: lighten($danger, 47%) !important;
		}
	}
	svg {
		position: absolute;
		top: $spacingBase;
		right: $spacingBase;
	}
}
