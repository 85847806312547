@import '../../../assets/scss/variables';

.input-radio {
	border-radius: $borderRadiusInput;
	transition: border-color 0.2s ease;
	:hover {
		cursor: pointer;
	}
	.radio-hidden {
		display: none;
		opacity: 0;
		visibility: hidden;
		&:checked {
			+ .fake-radio {
				&::before {
					animation: zoomIn 0.2s ease-in-out forwards;
				}
			}
		}
	}
	.label--radio {
		padding: $spacingHalf $spacingBase $spacingHalf 0;
		display: inline-flex;
		align-items: center;
	}
	&.required {
		.fake-radio {
			border-color: $danger !important;
			background-color: lighten($danger, 47%);
		}
	}
}
.fake-radio {
	background-color: $white;
	transition: background-color 0.2s ease;
	border: 1px solid darken($borderColor, 20%);
	border-radius: 100%;
	box-sizing: content-box;
	height: 20px;
	margin-right: $spacingBase;
	position: relative;
	width: 20px;
	z-index: 1;
	&::before {
		border-radius: 100%;
		background-color: $primary;
		content: '';
		height: 60%;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		transform-origin: 50%;
		width: 60%;
		z-index: 2;
		transition: opacity 0.2s ease;
	}
}
