@import '../../../../assets/scss/variables';

.boats {
	margin-bottom: $spacing3;
	.select--boat_types {
		width: auto;
	}
	.boat-field {
		display: flex;
		position: relative;
		.form-input {
			width: 280px;
		}
		.btn--add-boat {
			margin-left: $spacing3;
			height: 100%;
			width: auto;
		}
	}
	.btn--remove-boat {
		background-color: $primary !important;
		width: $spacing3;
		height: $spacing3;
		border-radius: 50%;
		position: absolute;
		align-items: center;
		display: flex;
		left: 248px;
		top: -12px;
		opacity: 1;
		background-color: $primaryLight !important;
		transition: background-color 0.2s ease;
		&:hover {
			background-color: $primary !important;
		}
	}
}
