@import '../../../assets/scss/variables';

.page--call-center {
	flex: 1;
	overflow-x: auto;

	.top-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.btn--add-new, .btn--make-call {
			margin: $spacing3;
		}
	}
	.dialog {
		width: 100%;
		margin-bottom: 0;
		&__content {
			padding: 0;
		}
		.rt-td {
			display: flex;
			align-items: center;
		}
		.rt-tr-group:nth-child(even) {
            background-color: #f9f9f9;
        }
	}
	.accounts--table {
		.toggle {
			&.disabled {
				.toggle__background__right,
				.toggle__background__left {
					opacity: 0.2;
					cursor: pointer !important;
				}
				.toggle, .toggle__circle {
					cursor: pointer !important;
				}
			}
		}
	}
}
