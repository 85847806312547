@import '../../../assets/scss/variables';
.input-text {
	position: relative;
	max-width: 100%;
	width: 100%;
	height: $spacing6;
	border-radius: $borderRadiusInput;
	input[type='text'],
	input[type='password'],
	input[type='number'] {
		width: 100%;
		height: 100%;
		background-color: $white;
		border: 1px solid $borderColor;
		padding: 0 $spacingBase;
		font-size: inherit;
		border-radius: $borderRadiusInput;
		transition: border-color 0.2s ease, background-color 0.2s ease;
		color: $primary;
		font-weight: 400;
		text-overflow: ellipsis;
		&:focus {
			border-color: $inputFocus;
		}
	}

	&.required {
		input {
			border-color: $danger !important;
			background-color: lighten($danger, 47%) !important;
		}
	}
	&.disabled {
		input {
			cursor: not-allowed;
		}
	}
	& > svg {
		height: 100%;
		position: absolute;
		top: 0;
		right: $spacingBase;
	}
	&.flat {
		height: $spacing5;
	}
	.password-eye-icon {
		display: flex;
		align-items: center;
		position: absolute;
		z-index: 1;
		right: $spacingBase;
		top: 0;
		height: 100%;
	}
	&.password {
		input {
			padding-right: $spacing5;
		}
	}
	&.has-icon {
		input {
			padding-right: $spacing5;
		}
		.password-eye-icon {
			right: $spacing3;
		}
		&.password {
			input {
				padding-right: $spacing7;
			}
		}
	}
}
