@import '../styles.scss';

.dialButton {
  @include common-button;
  height: 40px;
  width: 45px;
  background: #8f8ca7;
  margin-left: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 200px;
}

.dialButtonStrict{
  @include common-button;
  height: 80px;
  width: 80px;
  background: none;
  // background-color: #15c73b;
}

.dialInput {
  @include common-input;
  width: 100%;
}

.dialstringContainerStrict {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  padding: 0 0 0 60px;
}

.dialstringContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 3px;
  box-shadow: none;
  padding-right: 35px;
  select {
    cursor: pointer;
    border: 1px solid #e8e8e8;
    padding: 0 0 0 20px;
    height: 40px;
  }
  svg {
    position: absolute;
    right: 85px;
    margin: auto;
  }
}
