@import '../../../assets/scss/variables';

.page--contact-list {
	flex: 1;
	overflow-x: auto;
	.dialog {
		width: 100%;
		margin-bottom: 0;
		&__content {
			padding: 0;
			overflow-x: auto;
		}
	}
	.row {
		&__id {
			color: $textGrey;
			opacity: 0.6;
			font-size: 0.8rem;
		}
	}
	.input--search {
		width: 250px;
	}
	.filters {
		padding: $spacing3;
		min-height: 270px;
		&--row {
			margin-bottom: $spacing3;
			display: flex;
			align-items: center;
			& > div {
				margin-right: $spacing3;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		.form-group {
			margin-bottom: 0;
		}
	}
	.input-select.active select {
		background-color: $primaryLight;
		color: $white;
	}
	.multi--select {
		max-width: 200px;
	}

	.section--buttons {
		margin: 0 $spacing3 $spacing3 $spacing3;
	}
}
