@import '../../assets/scss/variables';

.dot {
	width: 12px;
	height: 12px;
	background-color: $medium;
	border-radius: 50%;
	&.success {
		background-color: $success;
	}
	&.warning {
		background-color: $warning;
	}
	&.danger {
		background-color: $danger;
	}
}
