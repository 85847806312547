@import '../../assets/scss/variables';

header {
	padding: $spacing3;
	border-bottom: 1px solid $borderColor;
	background-color: $white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 76px;
	z-index: 2;
	.logo {
		display: flex;
		align-items: center;
		h1 {
			margin-left: $spacingBase;
			font-size: 24px;
			@media screen and (max-width: 492px) {
				display: none;
			}
		}
		h3 {
			margin-left: $spacingBase;
			opacity: 0.5;
			@media screen and (max-width: 492px) {
				opacity: 1;
				font-size: 18px;
			}
			@media screen and (max-width: 350px) {
				opacity: 1;
				font-size: 14px;
			}
		}
	}
	.header__right {
		display: flex;
		align-items: center;
		> div {
			margin-right: $spacing2;
			&:last-child {
				margin: 0;
			}
		}
		.hello_user {
			@media screen and (max-width: 600px) {
				display: none;
			}
		}
		.logout {
			color: $danger;
			cursor: pointer;
		}
	}
}

.header__helper {
	height: 76px;
}
