@import '../../../../assets/scss/variables';

.dialog--calls {
	.call {
		margin: $spacing2;
		&:first-child {
			margin-top: $spacing2;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.phone-interface {
		border-bottom: 15px solid $borderColor;
		padding: 20px;
		padding-bottom: 30px;
		h1 {
			margin-bottom: 10px;
		}
		background: #fafafa;
	}
	audio::-webkit-media-controls-panel {
		background-color: rgba(143,140,167,0.3);
	}
	.react-audio-player {
		width: 100%;
	}
	.admin {
		margin: $spacing2 0;
		.name {
			font-weight: 400;
			color: $primaryDark;
		}
		.value {
			font-weight: 400;
			color: $primaryDark;
		}
		.title {
			color: $danger;
		}
		.call-player {
			margin-top: 20px;
		}
	}
}
