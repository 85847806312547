@import '../../../assets/scss/variables';

button.button-icon {
	opacity: 0.7;
	background-color: transparent !important;
	width: auto !important;
	transition: opacity 0.2s ease;
	padding: 0 $spacingBase !important;
	display: inline-flex;
	&:hover {
		opacity: 1;
		cursor: pointer;
	}
	&.circle {
		background-color: $primaryLight !important;
		width: $spacing3;
		height: $spacing3;
		border-radius: 50%;
		opacity: 1;
		transition: background-color 0.2s ease;
		&:hover {
			background-color: $primary !important;
		}
	}
}
