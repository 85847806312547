@import '../../../assets/scss/variables';

.sidenav-link {
	border-bottom: 1px solid lighten($primaryLight, 30%);
	cursor: pointer;
	background-color: $light;
	color: $primaryLight;
	transition: background-color 0.2s ease, color 0.2s ease;

	&:first-child {
		border-top-left-radius: $borderRadiusDialog;
		border-top-right-radius: $borderRadiusDialog;
	}
	&:last-child {
		border-bottom-left-radius: $borderRadiusDialog;
		border-bottom-right-radius: $borderRadiusDialog;
	}
	a {
		display: flex;
		align-items: center;
		padding: $spacing2 $spacing6 $spacing2 $spacing2;
		font-size: 1.1rem;
		white-space: nowrap;
		svg {
			margin-right: $spacing2;
		}
	}
	label {
		margin-top: 3px;
		cursor: pointer;
	}
	&.active,
	&:hover {
		color: $primary;
	}
	&.active {
		background-color: $white;
	}
	&:last-child {
		// border-bottom: none;
	}
}
