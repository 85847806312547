@import '../../../assets/scss/variables';

.page--faq {
	flex: 1;
	overflow-x: auto;
	.small-dialog {
		position: relative;
		.dialog__title {
			padding: 23px;
		}
		h3 {
			font-size: 1.2em;
		}
	}
	.top-buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		position: absolute;
		right: 0;
		top: -8px;
		.btn--add-new, .btn--make-call {
			margin: $spacing3;
		}
	}
	.parent-dialog {
      .dialog__content {

	  }
	}
	.dialog {
		width: 100%;
		margin-bottom: 0;
		&__content {
			padding: 0;
		}
		.rt-td {
			display: flex;
			align-items: center;
		}
		.rt-tr-group:nth-child(even) {
            background-color: #f9f9f9;
        }
	}
	.scenario--table {
		margin-bottom: 20px;
	}
	.faq--table, .scenario--table {
		background-color: #ffffff;
		height: 60vh;
		.toggle {
			&.disabled {
				.toggle__background__right,
				.toggle__background__left {
					opacity: 0.2;
					cursor: pointer !important;
				}
				.toggle, .toggle__circle {
					cursor: pointer !important;
				}
			}
		}
	}
}
