@import '../../../../assets/scss/variables';

.locations {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	&.preview {
		margin-top: 10px;
		.location-field {
			margin-bottom: 5px;
			padding-bottom: 5px;
			border-bottom: 1px solid $borderColor;
		}
		.label {
			margin-top: 10px;
		}
		.label-marker {
			cursor: default !important;
			height: 45px !important;
		}
		.btn--edit-locations {
			margin-top: 10px;
		}
		.locations-list {
			max-height: 235px;
		}
	}
	&.edit {
		.label-marker {
			&:hover {
				background-image: url("https://s3.eu-central-1.amazonaws.com/repository.bednblue.com/static/img/svg/marker-red.svg") !important;
			}
		}
		.locations-list {
			max-height: 425px;
			margin-bottom: 20px;
			padding-top: 15px;
			padding-right: 20px;
		}
	}
	.locations-list {
		display: flex;
		overflow: auto;
		flex-direction: column;
		.location-input-container {
			width: 100%;
		}
	}
	.location-field {
		margin-bottom: 15px;
		display: flex;
		position: relative;
		height: 50px;
		width: 100%;
		&.main-marina {
			order: 1;
		}
		order: 2;
		input {
			width: 100%;
			overflow: auto;
		}
		.label-marker {
			margin-top: 6px;
			height: 25px;
			width: 36px;
			background-repeat: no-repeat;
			cursor: pointer;
		}
	}
	.location-map {
		margin-bottom: $spacing3;
		border: 1px solid $borderColor;
		border-radius: 5px;
	}
	.btn--add-location, .btn--edit-locations {
		order: 999999;
	}
	.btn--remove-location {
		width: $spacing3;
		height: $spacing3;
		border-radius: 50%;
		position: absolute;
		align-items: center;
		display: flex;
		right: -10px;
		top: -12px;
		opacity: 1;
		background-color: $primaryLight !important;
		transition: background-color 0.2s ease;
		&:hover {
			background-color: $primary !important;
		}
	}
}
