@import '../../assets/scss/variables';

.loader-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	&.full {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&.white {
		.lds-ellipsis div {
			background: $white !important;
		}
	}
	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 64px;
		height: 12px;
	}
	.lds-ellipsis div {
		position: absolute;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background-color: $primary;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	.lds-ellipsis div:nth-child(1) {
		left: 6px;
		animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
		left: 6px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
		left: 26px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
		left: 45px;
		animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(19px, 0);
		}
	}
}
.fullscreen-loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	background-color: $light;
	&.fixed {
		position: fixed;
		z-index: 10000;
		left: 0;
		top: 0;
	}
}

.popup-loader {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
	.popup__dialog {
		height: 140px;
	}
	.popup__content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
