@import '../../../../assets/scss/variables';

.dialog--messages {
	.message-row {
		border: 1px solid #e8e8e8;
		border-radius: 5px;
		padding: $spacingBase;
		background-color: #fafafa;
		margin: $spacing2;
		word-break: break-word;
		&:first-child {
			margin-top: $spacing2;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&.recipient {
			.row {
				text-align: right;
			}
		}
		&.template {
			background: rgba(143, 140, 167, 0.4);
		}
		.row {
			margin-bottom: $spacingBase;
			&:last-child {
				margin-bottom: 0;
			}
			&__label {
				font-weight: 600;
			}
			&__value {
				font-weight: 600;
				color: $google;
			}
		}
		.bubble {
			position: relative;
			background: #00aabb;
			border-radius: .4em;
			line-height: 22px;
		}
		.discussion {
			max-width: 600px;
			margin: 0 auto;
			margin-top: 15px;
			display: flex;
			flex-flow: column wrap;
		}
		.discussion > .bubble {
			border-radius: 1em;
			padding: 0.25em 0.75em;
			margin: 0.0625em;
			max-width: 70%;
		}
		.discussion > .bubble.sender {
			align-self: flex-start;
			background-color: #efefef;
			color: $primary;
		}
		.discussion > .bubble.recipient {
			align-self: flex-end;
			background-color: $primaryLight;
			color: #fff;
		}
		.discussion > .bubble.sender.first { border-bottom-left-radius: 0.1em; }
		.discussion > .bubble.sender.last { border-top-left-radius: 0.1em; }
		.discussion > .bubble.sender.middle {
			border-bottom-left-radius: 0.1em;
			border-top-left-radius: 0.1em;
		}
		.discussion > .bubble.recipient.first { border-bottom-right-radius: 0.1em; }
		.discussion > .bubble.recipient.last { border-top-right-radius: 0.1em; }
		.discussion > .bubble.recipient.middle {
			border-bottom-right-radius: 0.1em;
			border-top-right-radius: 0.1em;
		}
	}
}
