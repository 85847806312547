@import '../../../assets/scss/variables';

.popup--view-helpdesk {
	&__description {
		margin-bottom: $spacing3;
	}
	.popup--helpdesk__buttons {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 60px;
	}
	.popup__content {
		height: calc(60vh + 100px);
	}

	.faq__show-all {
		padding-right: 15px;
		.faq-question {
			line-height: 25px;
			color: $primaryDark;
		}
		.faq-answer {
			color: $primaryLight;
			line-height: 25px;
		}
	}

	.scenario-tab-container, .faq-tab-container {
		margin: 10px;
		padding: 20px;
		height: 100%;
		.label__title__big {
			font-size: 18px;
		}
	}

	.faq-show, .scenario-show {
		overflow-y: auto;
		height: calc(100% - 80px);
	}
	.scenario-show {
		padding: 20px;
		.scenario {
			line-height: 25px !important;
			li, p {
				line-height: 25px;
			}
		}
	}

	.faq-single {
		padding: 10px;
	}

	.top-menu {
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
	}
}
