@import '../../../assets/scss/variables';

.multiple__item {
  position: relative;
  &.scroll {
    max-height: 310px;
    overflow-y: auto;
    margin-bottom: 15px;
  }
	&__row {
		display: flex;
		margin-bottom: $spacingBase;
		align-items: flex-start;
		button {
			margin-top: 8px;
		}
	}
  &__wrapper {
    position: relative;
  }
  .link-anchor {
    position: absolute;
    right: 40px;
    top: 8px;
  }
}
