@import '../../assets/scss/variables';

.snackbar {
	position: fixed;
	bottom: $spacing3;
	max-width: calc(100% - #{$spacing6});
	right: $spacing3;
	color: $white;
	border-radius: $borderRadiusBtn;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10010;
	padding: $spacingBase;
	background-color: $primary;
	&.danger {
		background-color: $danger;
	}
	&.success {
		background-color: $success;
	}
	&.warning {
		background-color: $warning;
	}
	.close {
		margin-left: $spacing2;
		&:hover {
			cursor: pointer;
		}
	}
}

.snackbar-enter {
	transform: translateY(100% + 64);
}

.snackbar-enter.snackbar-enter-active {
	transform: translateY(0);
	transition: transform 0.2s cubic-bezier(0, 0, 0, 1.48);
}

.snackbar-exit {
	transform: translateY(0);
}

.snackbar-exit.snackbar-exit-active {
	transform: translateY(100% + 64);
	transition: transform 0.2s cubic-bezier(1, -0.68, 0.5, 1);
}
