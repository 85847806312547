@import './variables';
@font-face {
	font-family: 'Averta';
	src: url('../fonts/340430_2_0.ttf');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Averta';
	src: url('../fonts/340430_2_0.ttf');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Averta';
	src: url('../fonts/340430_3_0.ttf');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Averta';
	src: url('../fonts/340430_0_0.ttf');
	font-weight: 800;
	font-style: normal;
}

html {
	font-size: $spacing2;
	font-family: $mainFont;
}
body {
	font-size: 1rem;
	font-family: $mainFont;
	font-weight: $normal;
}
p {
	margin: 0;
}
blockquote {
	color: $primary;
	font-size: 1.5rem;
	font-style: italic;
	line-height: 1.6;
	margin: $spacing3;
}
small {
	font-size: 0.8rem;
}
h3 {
	// font-size: 1.25rem;
	margin: 0;
}
h2 {
	// font-size: 1.5rem;
	margin: 0;
}
h1 {
	// font-size: 2rem;
	margin: 0;
}
.super {
	font-size: 4rem;
	line-height: 1;

	@media screen and (max-width: 768px) {
		font-size: 3rem;
	}

	@media screen and (max-width: 425px) {
		font-size: 2.5rem;
	}
}
