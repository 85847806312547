@import '../../../assets/scss/variables';

.dialog--contact-form {
	max-width: 570px;
}
.double-text-select {
	box-shadow: none;
	border: 1px solid $borderColor;
}
.input-row {
	display: flex;
	align-items: flex-start;
	margin-bottom: $spacingBase;
	&:last-child {
		margin-bottom: 0;
	}
}
.phones-container {
	display: flex;
	flex-direction: column;
	.btn-remove-item {
		margin-top: 32px;
		margin-left: 3px;
	}
}