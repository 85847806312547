@import './variables';

@mixin common-button {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: $primary-color;
  color: $almost-black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:active {
    transition: none;
    opacity: 0.5;
  }
}

@mixin common-input {
  border: none;
  padding: none;
  &:focus {
    outline: none;
  }
  font-size: 1em;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}
