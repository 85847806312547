@import '../../../assets/scss/variables';

.input-select {
	position: relative;
	display: inline-block;
	transition: border-color 0.2s ease;
	min-width: 135px;
	select {
		background-color: $white;
		border: 1px solid transparent;
		transition: background-color 0.2s ease;
		border-radius: $borderRadiusInput;
		color: $primary;
		padding: 0 $spacing4 0 $spacingBase;
		height: $spacing6;
		overflow: hidden;
		white-space: pre;
		text-overflow: ellipsis;
		font-size: 1rem;
		width: 100%;
		cursor: pointer;
		&:focus {
			border-color: $inputFocus;
		}
		&:disabled {
			opacity: 0.7;
			cursor: not-allowed;
		}
	}
	&.flat {
		select {
			height: $spacing5;
		}
	}
	svg {
		position: absolute;
		right: $spacingSS;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	&.full-width {
		width: 100%;
		select {
			width: 100%;
		}
	}
	&.flat {
		margin: 0;
		select {
			border: 1px solid $borderColor;
			border-radius: $borderRadiusInput;
			box-shadow: none;
			&:focus {
				border-color: $inputFocus;
			}
		}
	}
	&.invisible {
		select {
			height: $spacing2 !important;
			width: $spacing4 !important;
			opacity: 0 !important;
			border: none !important;
			padding: 0 !important;
			box-shadow: none !important;
		}
	}
	&.invisible-but-works {
		select {
			height: 18px !important;
			width: 72px !important;
			border: none !important;
			background: none !important;
			padding: 0 !important;
			box-shadow: none !important;
			color: #9e9bb3 !important;
		}
		svg {
			display: none;
		}
	}
	&.required {
		select {
			border-color: $danger;
			background-color: lighten($danger, 47%);
		}
	}
}
