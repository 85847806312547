@import './variables';

@keyframes zoomIn {
	0% {
		transform: translateX(-50%) translateY(-50%) scale(0);
	}
	70% {
		transform: translateX(-50%) translateY(-50%) scale(1.1);
	}
	100% {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}

$transitionDuration: 0.2s;

// =========== FADE =============

.fade-enter {
	opacity: 0;
}

.fade-enter.fade-enter-active {
	transition: opacity 0.4s ease;
	opacity: 1;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 0.4s ease;
}

.fade-enter-done {
	opacity: 1 !important;
}

// =================================

// ---------------------------------

// =========== FADE UP =============

.fade-up-enter {
	opacity: 0.01;
	transform: translateY(50px);
}

.fade-up-enter.fade-up-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity $transitionDuration ease,
		transform $transitionDuration ease;
}

.fade-up-exit {
	transform: translateY(0);
	opacity: 1;
}

.fade-up-exit.fade-up-exit-active {
	transform: translateY(50px);
	opacity: 0.01;
	transition: opacity $transitionDuration ease,
		transform $transitionDuration ease;
}

// =================================

// ---------------------------------

// =========== FORM VALIDATION =============

.form-validation-enter {
	opacity: 0.01;
	max-height: 0;
}

.form-validation-enter.form-validation-enter-active {
	opacity: 1;
	max-height: 80px;
	transition: opacity 0.2s ease, max-height 0.2s ease;
}

.form-validation-exit {
	max-height: 80px;
	opacity: 1;
}

.form-validation-exit.form-validation-exit-active {
	max-height: 0;
	opacity: 0.01;
	transition: opacity 0.2s ease, max-height 0.2s ease;
}

.form-validation-enter-done {
	max-height: 80px;
}

// =================================

// ---------------------------------

// =========== HEIGHT OPACITY =============

.height-opacity-enter {
	opacity: 0.01;
	max-height: 0;
}

.height-opacity-enter.height-opacity-enter-active {
	opacity: 1;
	max-height: 80px;
	transition: opacity 0.2s ease, max-height 0.2s ease, padding 0.2s ease;
}

.height-opacity-enter-done {
	max-height: 80px;
}

.height-opacity-exit {
	max-height: 80px;
	opacity: 1;
}

.height-opacity-exit.height-opacity-exit-active {
	max-height: 0;
	opacity: 0.01;
	padding: 0;
	transition: opacity 0.2s ease, max-height 0.2s ease, padding 0.2s ease;
}

// =================================
