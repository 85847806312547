@import './phone/Phone.scss';

.container-box {
  width: auto;
  margin-top: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
  top: -10px;
}

.userString {
  padding: 2px;
}

.userStringLarge{
  padding: 2px;
  font-size: 25px;
}

#settingsButton {
  @include common-button;
  background: none;
  height: 27px;
  width: 27px;
  padding: 2px;
  opacity: 0.3;
  &.on {
    opacity: 1;
  }
  svg {
    fill: #201a50;
  }
}

#settingsMenu {
  flex-direction: column;
  transition: all 0.2s ease;
  margin-top: 10px;
  height: 100px;
  width: 100%;
  justify-content: flex-start;
  &.closed {
    margin-top: 0px;
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
}

#dropdowns {
  width: 100%;
}

.dropdownRow {
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0;
}

.dropdownIcon {
  padding: 0 5px 0 5px;
}
