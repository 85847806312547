@import '../../../../assets/scss/variables';

.dialog--contact-history {
	.inner-content {
		padding-bottom: 15px;
		max-height: 556px;
		overflow-x: auto;
	}
	.record-row {
		border: 1px solid #e8e8e8;
		border-radius: 5px;
		padding: $spacingBase;
		background-color: #fafafa;
		margin: $spacing2;
		word-break: break-word;

		@media(min-width: 565px){
			&.column {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				.row {
					margin-bottom :0;
					&:first-child {
						width: calc(100% - 295px);
					}
					width: 130px;
					&:last-child {
						width: 155px;
						text-align: right;
					}
				}
			}
		}
		&:first-child {
			margin-top: $spacing2;
		}
		&:last-child {
			margin-bottom: 0;
		}
		.row {
			margin-bottom: $spacingBase;
			&:last-child {
				margin-bottom: 0;
			}
			&__label {
				font-weight: 600;
			}
			&__value {
				font-weight: 600;
				color: $google;
				&.space-right {
					margin-right: 20px;
				}
			}
			.history-changes {
				margin-top: 15px;
				margin-left: 20px;
				li {
					margin-bottom: 10px;
					line-height: 23px;
				}
			}
		}
	}
}
