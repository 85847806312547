@import '../../../assets/scss/variables';

.page--users {
	flex: 1;
	overflow-x: auto;
	.btn--add-new {
		margin: $spacing3;
	}
	.dialog {
		width: 100%;
		margin-bottom: 0;
		&__content {
			padding: 0;
		}
		.rt-td {
			display: flex;
			align-items: center;
		}
		.rt-tr-group:nth-child(even) {
            background-color: #f9f9f9;
        }
	}
}
