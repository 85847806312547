@import '../../../assets/scss/variables';

.form-group {
	margin-bottom: $spacing3;
	.form-input {
		margin-bottom: 0;
	}
	&--double {
		display: flex;
		> * {
			width: 100%;
		}
		> :first-child {
			margin-right: $spacing3 !important;
		}
	}
}
