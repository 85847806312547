@import '../../../assets/scss/variables';

.popup--review-call {
  .countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 27px;
    padding-bottom: 20px;
    padding-top: 10px;
    .negative-timer {
      color: red;
      display: none;
    }
    .overtime-title {
      color: red;
      font-size: 18px;
      display: none;
    }
  }
}
