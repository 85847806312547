@import '../../../assets/scss/variables';

.form-input {
	border-color: 1px solid transparent;
	transition: border-color 0.2s ease;
	max-width: 100%;
	margin-bottom: $spacing2;
	position: relative;
	transition: width 0.2s ease;
	select,
	input[type='text'],
	input[type='password'] {
		width: 100%;
	}
	&.required {
		overflow: hidden;
	}
	&__validation-error {
		color: $danger;
		transition: height 0.2s ease;
		&__text {
			box-sizing: content-box;
			padding: $spacingHalf 0;
		}
	}
	&.full-width {
		width: 100%;
		margin-right: 0;
	}
	&__caption {
		margin-top: $spacingBase;
		color: $primaryLight;
		font-size: 0.9rem;
		position: absolute;
	}
	&.has-error {
		.form-input__caption {
			position: relative;
		}
	}
}
