@import '../../../assets/scss/variables';

.form-input__validation-error {
	color: $danger;
	margin-bottom: $spacing2;
}

.form-input {
	.form-input__validation-error {
		margin-bottom: 0;
	}
}
