@import '../../assets/scss/variables';

.dialog {
	border: 1px solid $borderColor;
	border-radius: $borderRadiusDialog;
	color: $primary;
	// overflow: hidden;
	margin-bottom: $spacing5;
	transition: border-color 0.2s ease;
	&__title {
		color: #9e9bb3;
		font-size: 1.1rem;
		font-weight: 400;
		background-color: #f1f1f4;
		padding: $spacing2;
		border-bottom: 1px solid $borderColor;
		border-top-left-radius: $borderRadiusDialog;
		border-top-right-radius: $borderRadiusDialog;
	}
	&__description {
		color: #9e9bb3;
		font-size: 1rem;
		font-weight: 400;
		background-color: lighten(#f1f1f4, 2%);
		padding: $spacing2;
		border-bottom: 1px solid $borderColor;
		border-top-left-radius: $borderRadiusDialog;
		border-top-right-radius: $borderRadiusDialog;
	}
	&__content {
		padding: $spacing2;
		background-color: $white;
		border-bottom-left-radius: $borderRadiusDialog;
		border-bottom-right-radius: $borderRadiusDialog;
	}
	&.has-error {
		border-color: $danger;
	}
}
