@import '../../../assets/scss/variables';

.page--custom-email__editor {
  margin-bottom: 70px;
}

.submit-scenario-btn {
  position: absolute;
  bottom: 22px;
  left: calc(50% - 90px);
  width: 180px;
}