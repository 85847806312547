@import '../../../assets/scss/variables';

.card--statistics {
	border: 1px solid $borderColor;
	border-radius: 6px;
	background-color: $white;
	box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 300px;
	min-height: 150px;
	overflow: hidden;
	padding: $spacing2;
	color: $primaryLight;
	display: inline-flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-between;
	.number {
		font-size: 4rem;
		margin-right: $spacingBase;
		font-weight: 600;
		&--arr {
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
	.title {
		font-size: 1.2rem;
	}
	.bottom {
		width: 100%;
	}
	.success {
		color: $success;
	}
	.warning {
		color: $warning;
	}
	.danger {
		color: $danger;
	}
	.stats-num {
		margin-right: $spacingBase;
		font-size: 1.1rem;
	}
	.top-stats, .middle {
		margin-top: 10px;
	}
}
