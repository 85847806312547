@import '../../../assets/scss/variables';

.page--dashboard {
	overflow-x: auto;
	padding: $spacing3;
	flex: 1;
	.filters {
		margin-bottom: $spacing3;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.card--statistics {
		margin-right: $spacing3;
		margin-bottom: $spacing3;
	}
	.dates {
		display: flex;
		flex-direction: column;
		.form-group:first-child {
			margin-right: $spacing3;
		}
	}
	.date-label {
		display: flex;
		align-items: center;
		margin-bottom: $spacingBase;
		label {
			margin-bottom: 0;
			margin-right: $spacingBase;
		}
	}
	.input-select.active select {
		background-color: $primaryLight;
		color: $white;
	}
}
