@import '../../../assets/scss/variables';

.popup--new-call {
  .popup__content {
    .container {
      @media(max-width: 500px){
        width: auto !important;
        .dialstringContainer {
          flex-direction: column !important;
          .dialInput {
            margin-bottom: 20px;
          }
          .dialButton {
            width: 40px;
          }
        }
        .container-box {
          top: 32px;
        }
      }
    }
  }
}
